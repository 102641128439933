<div class="bg-purple rounded p-3 row mt-4">
  <div class="d-lg-flex justify-content-between align-items-center text-lg-start text-center">
    <div>
      <h2 class="text-white">Vous souhaitez mettre en vente votre bien ou estimer ?</h2>
      <p class="text-white  my-lg-0 my-3"><small>Contactez notre négociateur, Arthur PINEL au 06 09 89 23 73</small></p>
    </div>
    <div>
      <a href="/contact" class="btn btn-yellow"> CONTACTEZ-NOUS </a>
    </div>
  </div>
</div>
