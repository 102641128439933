import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { ActualiteService } from 'src/app/_services/actualite.service';
import { BiensService } from 'src/app/_services/biens.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { SliderService } from 'src/app/_services/slider.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('slickModal') slickModal!: SlickCarouselComponent;

  commune: string ='Communes';
  type: string ='Type de biens';
  around: boolean = false;
  loca: boolean = false;
  achat: string = "toutes";
  arrayLength: any;
  actualites: any;
  biens: any;
  typeBien: any;
  sliders: any;
  images: any;
  communes: any;
  slides = [1, 2, 3, 4];
  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: false,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          centerMode: false,
          slidesToShow: 2,
          infinite: true,
        }
      },
      {
        breakpoint: 968,
        settings: {
          arrows: false,
          centerMode: false,
          slidesToShow: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 460,
        settings: {
          arrows: false,
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ]
  };

  constructor(private title: Title, private actualiteService: ActualiteService,
    private biensService: BiensService, private router: Router, private sliderService: SliderService) {
    this.title.setTitle('Accueil - Office notarial Pacé');
  }

  ngOnInit(): void {
    this.getActualites();
    this.getBiens();
    this.getTypeBiens();
    this.getCommunes();
    this.getSliders();
  }

  getTypeBiens() {
    this.biensService.getTypeBiens().subscribe(data => {
      this.typeBien = data;
    })
  }

  getSliders() {
    this.sliderService.getSliders().subscribe(data => {
      this.sliders = data;
      this.images = this.sliders[0].images;
      this.arrayLength = this.images.length;
      // console.log(this.images);

    })
  }

  getCommunes() {
    this.biensService.getCommunes().subscribe(data => {
      this.communes = data.villes;
    })
  }

  getBiens() {
    this.biensService.getBiens().subscribe(data => {
      this.biens = data;
      this.slides = this.biens;
    })
  }

  getActualites() {
    this.actualiteService.getActualites().subscribe(data => {
      this.actualites = data;
    })
  }

  prev() {
    this.slickModal.slickPrev();
  }

  next() {
    this.slickModal.slickNext();
  }

  onFormSubmit(findBien:NgForm) {
    const queryParams = {
      'transaction': this.achat,
      'commune': this.commune === 'Communes' ? 'toutes' : this.commune,
      "type": this.type === 'Type de biens' ? 'tous' : this.type,
      "around": this.around,
    };
    this.router.navigate(['offres'], { queryParams: queryParams })
  }
}
