// redirections.ts

export const redirections = [
  { path: 'biens-en-vente', redirectTo: '/offres', pathMatch: 'full' as const},
  { path: 'biens-en-location', redirectTo: '/offres', pathMatch: 'full' as const},
  { path: 'bien/vente-maison-rennes-35-55m2-3pieces', redirectTo: '/offres', pathMatch: 'full' as const},
  { path: 'bien/vente-maison-pace-35-111m2-5pieces', redirectTo: '/offres', pathMatch: 'full' as const},
  { path: 'bien/vente-appartement-rennes-35-31m2-1piece', redirectTo: '/offres', pathMatch: 'full' as const},
  { path: 'bien/vente-maison-pace-35-205m2-7pieces', redirectTo: '/offres', pathMatch: 'full' as const},
  { path: 'bien/vente-maison-geveze-35-160m2-6pieces', redirectTo: '/offres', pathMatch: 'full' as const},
  { path: 'bien/vente-maison-pace-35-148m2-7pieces', redirectTo: '/offres', pathMatch: 'full' as const},
  { path: 'bien/vente-appartement-pace-35-47m2-2pieces-3', redirectTo: '/offres', pathMatch: 'full' as const},
  { path: 'bien/vente-appartement-rennes-35-60m2-3pieces-6', redirectTo: '/offres', pathMatch: 'full' as const},
  { path: 'bien/vente-appartement-saint-gregoire-35-80m2-4pieces', redirectTo: '/offres', pathMatch: 'full' as const},
  { path: 'bien/vente-appartement-le-rheu-35-84m2-4pieces', redirectTo: '/offres', pathMatch: 'full' as const},
  { path: 'bien/vente-maison-saint-meen-le-grand-35-80m2-3pieces-2', redirectTo: '/offres', pathMatch: 'full' as const},
];
