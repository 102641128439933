import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-offer',
  templateUrl: './home-offer.component.html',
  styleUrls: ['./home-offer.component.scss']
})
export class HomeOfferComponent implements OnInit {
  @Input() bien: any;
  titre: string = '';
  constructor() {}

  ngOnInit(): void {
    switch (this.bien.type) {
      case 'APPT':
        this.titre = this.bien.transaction === 'location' ? 'Location' + '- Appartement - ' + this.bien.ville + '(' + this.bien.departement +') - ' + this.bien.surfaceHabitable + 'm² - ' + this.bien.nbPieces + ' pièces' : 'Vente' + '- Appartement - ' + this.bien.ville + '(' + this.bien.departement +') - ' + this.bien.surfaceHabitable + 'm² - ' + this.bien.nbPieces + ' pièces';
        break;
      case 'FMCO':
        this.titre = this.bien.transaction === 'location' ? 'Location'+ '- Local commercial - ' + this.bien.ville + '(' + this.bien.departement +') - ' + this.bien.surfaceHabitable + 'm² - ' + this.bien.nbPieces + ' pièces' : 'Vente' + '- Local commercial - ' + this.bien.ville + '(' + this.bien.departement +') - ' + this.bien.surfaceHabitable + 'm² - ' + this.bien.nbPieces + ' pièces';
        break;
      case 'MAIS':
        this.titre = this.bien.transaction === 'location' ? 'Location'+ '- Maison - ' + this.bien.ville + '(' + this.bien.departement +') - ' + this.bien.surfaceHabitable + 'm² - ' + this.bien.nbPieces + ' pièces' : 'Vente' + '- Maison - ' + this.bien.ville + '(' + this.bien.departement +') - ' + this.bien.surfaceHabitable + 'm² - ' + this.bien.nbPieces + ' pièces';
        break;
      case 'IMMR':
        this.titre = this.bien.transaction === 'location' ? 'Location' + '- IMMR - ' + this.bien.ville + '(' + this.bien.departement +') - ' + this.bien.surfaceHabitable + 'm² - ' + this.bien.nbPieces + ' pièces' : 'Vente' + '- IMMR - ' + this.bien.ville + '(' + this.bien.departement +') - ' + this.bien.surfaceHabitable + 'm² - ' + this.bien.nbPieces + ' pièces';
        break;
      case 'PROP':
        this.titre = this.bien.transaction === 'location' ? 'Location'+ '- Propriété - ' + this.bien.ville + '(' + this.bien.departement +') - ' + this.bien.surfaceHabitable + 'm² - ' + this.bien.nbPieces + ' pièces' : 'Vente' + '- Propriété - ' + this.bien.ville + '(' + this.bien.departement +') - ' + this.bien.surfaceHabitable + 'm² - ' + this.bien.nbPieces + ' pièces';
        break;
      case 'TEBA':
        this.titre = this.bien.transaction === 'location' ? 'Location'+ '- Propriété - ' + this.bien.ville + '(' + this.bien.departement +') - ' + this.bien.surfaceHabitable + 'm² - ' + this.bien.nbPieces + ' pièces' : 'Vente' + '- Propriété - ' + this.bien.ville + '(' + this.bien.departement +') - ' + this.bien.surfaceHabitable + 'm² - ' + this.bien.nbPieces + ' pièces';
        break;
    }
  }

}
