import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ActualiteService } from 'src/app/_services/actualite.service';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent implements OnInit {
  slug: any;
  actualite: any;

  constructor(private title: Title,
    private route: ActivatedRoute,
    private actuService: ActualiteService) {
      this.slug = this.route.snapshot.paramMap.get('slug');
    this.title.setTitle('Actualités - Office notarial Pacé');
  }

  ngOnInit(): void {
    this.getActu();
  }

  getActu() {
    this.actuService.getActualite(this.slug).subscribe(data => {
      this.actualite = data;
    })
  }
}
