<app-intro title="Acheter ou louer"></app-intro>
<div>
  <div class="container-fluid p-5 bg-grey">
    <div class="container text-center">
      <h2 class="text-cormorant">Vous rechercher un bien à vendre ou en location ?</h2>
    </div>
  </div>
  <div class="container text-lg-start text-center mt-3">
    <form class="py-3 px-3 bg-purple-gradient rounded mt-lg-0" (ngSubmit)="onFormSubmit(findBien.value)" #findBien="ngForm">
      <div class="d-lg-flex">
        <div class="form-group my-3 col-lg-6">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="achat" [(ngModel)]="achat" name="achat">
            <label class="form-check-label text-white " for="inlineRadio1">ACHETER</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="location" [(ngModel)]="achat" name="achat">
            <label class="form-check-label text-white" for="inlineRadio2">LOUER</label>
          </div>
        </div>
        <div class="form-group my-3 col-lg-6">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" name="radioNoLabel" id="radioNoLabel1" value=""
                   aria-label="..." [(ngModel)]="around" name="around">
            <label class="form-check-label text-white"> COMMUNES ALENTOURS</label>
          </div>
        </div>
      </div>
      <div class="d-flex row">
        <div class=" col-lg-4 mb-3 mb-lg-0">
          <select class="form-select" aria-label="Default select example" [(ngModel)]="type" name="type">
            <option selected>Type de biens</option>
            <option *ngFor="let type of typeBiens" value="{{type.id}}">{{type.designation}}</option>
          </select>
        </div>
        <div class="col-lg-4">
          <select class="form-select" aria-label="Default select example" [(ngModel)]="commune" name="commune">
            <option selected>Communes</option>
            <option *ngFor="let city of communes" value="{{city}}">{{city}}</option>
          </select>
        </div>
        <div class=" col-lg-4 text-center mt-3 mt-lg-0">
          <button type="submit" class="btn btn-yellow">Rechercher</button>
          <button class="btn btn-yellow mx-1" (click)="resetForm(findBien.value)">Réinitialiser</button>
        </div>
      </div>
    </form>

    <div class="my-5">
      <div class="card-container">
        <ng-container *ngFor="let annonce of biens | paginate: { itemsPerPage: 12, currentPage: p }">
          <app-home-offer class="border card-offer" [bien]="annonce"></app-home-offer>
        </ng-container>
      </div>
      <div class="text-center my-3">
        <pagination-controls class="custom" previousLabel="précédent" nextLabel="suivant" (pageChange)="p = $event"></pagination-controls>
      </div>
    </div>
    <div class="mb-3">
      <app-contact-shortcut ></app-contact-shortcut>
    </div>
  </div>
</div>
