import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ContactService } from 'src/app/_services/contact.service';
import { RecaptchaService } from 'src/app/_services/recaptcha.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  name: string = '';
  firstName: string = '';
  address: string = '';
  postalCode: string = '';
  city: string = '';
  email: string = '';
  message: string = '';
  consentement: boolean = false;
  formSuccess: boolean = false;
  formError: boolean = false;

  constructor(
    private http: HttpClient,
    private title: Title,
    private contactService: ContactService,
    private recaptchaService: RecaptchaService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.title.setTitle('Contact - Office notarial Pacé');
  }

  ngOnInit(): void {
  }

  public send(form: NgForm): void {
    this.formSuccess = false;
    this.formError = false;
    if (form.invalid || !this.consentement) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      this.formError = true;
      return;
    }

    this.recaptchaV3Service.execute('importantAction')
    .subscribe((token: string) => {
      // console.debug(`Token [${token}] generated`);

      const formData = { ...form.value, recaptchaToken: token };

          this.contactService.sendContact(formData).subscribe(
            (data) => {
              // Gestion de la réussite
              this.resetForm(form);
              this.formSuccess = true;
            },
            (error) => {
              // console.log(error);
              this.formError = true;
              ;
            }
          );
    });
  }

  onFormSubmit(contactForm:NgForm){
    if (this.consentement) {
      this.recaptchaService.execute('submit_form').subscribe(
        (token) => {
          // console.log(contactForm);

          // Créez une copie de l'objet des données du formulaire et ajoutez le token reCAPTCHA
          const formData = { ...contactForm, recaptchaToken: token };

          this.contactService.sendContact(formData).subscribe(
            (data) => {
              // Gestion de la réussite
            },
            (error) => {
              // console.log(error);
              ;
            }
          );
        },
        (error) => {
          console.error('Erreur lors de la récupération du token reCAPTCHA:', error);
        });
    } else {
      alert("Acceptez les conditions :)");
    }
  }

  resetForm(contactForm:NgForm){
    contactForm.reset();
  }

}
