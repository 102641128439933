import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

const API = `${environment.api}/api`;

@Injectable({
  providedIn: 'root'
})
export class BiensService {

  constructor(private http: HttpClient) { }

  getAllBiens(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    return this.http.get(API + '/biens/displayed', httpOptions);
  }

  getBiens(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    return this.http.get(API + '/biens/homeCarousel', httpOptions);
  }

  findBien(info: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };

    var tabData = {};
    tabData = {
      'transaction': info?.transaction,
      'commune': info?.commune,
      "type": info?.type,
      "around": info?.around,
    };
    const body = tabData;

    return this.http.post(API + '/findbiens', body, httpOptions);
  }

  getTypeBiens(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    return this.http.get(API + '/typebien', httpOptions);
  }

  getCommunes(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    return this.http.get(API + '/communes', httpOptions);
  }

  getBien(slug: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    return this.http.get(API + '/offre/' + slug, httpOptions);
  }
}
