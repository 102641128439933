import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

const API = `${environment.api}/api`;

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  sendContact(info: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };

    var tabData = {};
    tabData = {
      'nom': info.name,
      'prenom': info.firstName,
      "email": info.email,
      "adresse": info.address,
      "cp": info.postalCode,
      "ville": info.city,
      "message": info.message,
      'token': info.recaptchaToken
    };
    const body = tabData;

    return this.http.post(API + '/contact', body, httpOptions);
  }
}
