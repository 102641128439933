import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {

  private siteKey: string = '6LfqbJMlAAAAAOYPGv9PqdGQLW8qUEbpBDDyigdT';
  private secretKey: string = '6LfqbJMlAAAAAD2YdaEkpBcnKqHWPiipuEENfZfk';
  private apiUrl: string = 'https://www.google.com/recaptcha/api/siteverify';

  constructor(private http: HttpClient) { }

  execute(action: string): Observable<string> {
    return new Observable(observer => {
      (window as any).grecaptcha.ready(() => {
        (window as any).grecaptcha.execute(this.siteKey, { action }).then((token: string) => {
          observer.next(token);
          observer.complete();
        });
      });
    });
  }

  verify(token: string): Observable<any> {
    const body = new FormData();
    body.append('secret', this.secretKey);
    body.append('response', token);

    return this.http.post<any>(this.apiUrl, body);
  }
}
