import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import {Title} from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BiensService } from 'src/app/_services/biens.service';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {

  commune: string ='Communes';
  type: string ='Type de biens';
  around: boolean = false;
  loca: boolean = false;
  achat: string = 'toutes';

  biens: any;
  typeBiens: any;
  communes: any;
  p: number = 1;

  constructor(
    private title: Title,
    private biensService: BiensService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.title.setTitle('Offres - Office notarial Pacé');
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length === 0) {
        this.getBiens();
      } else {
        this.findBiens(params);
      }
    });
    this.getCommunes();
    this.getTypeBiens();
  }

  getBiens() {
    this.biensService.getAllBiens().subscribe(data => {
      this.biens = data;
    })
  }

  findBiens(params: any) {
    this.biensService.findBien(params).subscribe(data => {
      this.biens = data;
    })
  }
  getTypeBiens() {
    this.biensService.getTypeBiens().subscribe(data => {
      this.typeBiens = data;
    })
  }
  getCommunes() {
    this.biensService.getCommunes().subscribe(data => {
      this.communes = data.villes;
    })
  }

  onFormSubmit(findBien:NgForm) {
    const queryParams = {
      'transaction': this.achat,
      'commune': this.commune === 'Communes' ? 'toutes' : this.commune,
      "type": this.type === 'Type de biens' ? 'tous' : this.type,
      "around": this.around,
    };
    this.router.navigate(['offres'], { queryParams: queryParams })
  }

  resetForm(findBien:NgForm){
    this.commune = 'Communes';
    this.type = 'Type de biens';
    this.achat = 'toutes';
    const queryParams = {
      'transaction': 'toutes',
      'commune': 'toutes',
      "type": 'tous',
      "around": false,
    };
    this.router.navigate(['offres'], { queryParams: queryParams });
  }
}
