import { Component, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

declare var ga: Function;
declare global {
  interface Window {
    tarteaucitron: any; // Définissez le type de tarteaucitron ici
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'office-notarial-pace';
  isCollapsed = true;

  constructor(
    public router: Router,
    private renderer: Renderer2
  ) {
  }

  ngOnInit(): void {
    this.loadTarteaucitron();
  }

  loadTarteaucitron() {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/tarteaucitronjs/1.14.0/tarteaucitron.min.js';

    // Attendez le chargement de la page
    script.onload = () => {
      // const tarteaucitron: any = window['tarteaucitron'] as any; // Définissez le type de tarteaucitron
      window.tarteaucitron.init({
        "privacyUrl": "/mentions-legales", /* Privacy policy url */

        "hashtag": "#tarteaucitron", /* Open the panel with this hashtag */
        "cookieName": "tarteaucitron", /* Cookie name */

        "orientation": "bottom", /* Banner position (top - bottom) */

        "groupServices": false, /* Group services by category */

        "showAlertSmall": false, /* Show the small banner on bottom right */
        "cookieslist": true, /* Show the cookie list */

        "closePopup": true, /* Show a close X on the banner */

        "showIcon": true, /* Show cookie icon to manage cookies */
        // "iconSrc": "./../../assets/images/cookie.png",
        "iconPosition": "BottomLeft", /* BottomRight, BottomLeft, TopRight and TopLeft */

        "adblocker": false, /* Show a Warning if an adblocker is detected */

        "DenyAllCta": true, /* Show the deny all button */
        "AcceptAllCta": true, /* Show the accept all button when highPrivacy on */
        "highPrivacy": true, /* HIGHLY RECOMMANDED Disable auto consent */

        "handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */

        "removeCredit": true, /* Remove credit link */
        "moreInfoLink": false, /* Show more info link */

        "useExternalCss": false, /* If false, the tarteaucitron.css file will be loaded */
        "useExternalJs": false, /* If false, the tarteaucitron.js file will be loaded */

        //"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for multisite */

        "readmoreLink": "", /* Change the default readmore link */

        "mandatory": true, /* Show a message about mandatory cookies */
      });

      window.tarteaucitron.user.matomoId = 45;
      (window.tarteaucitron.job = window.tarteaucitron.job || []).push('matomo');
    };

    // Ajoutez le script au DOM
    this.renderer.appendChild(document.body, script);
  }
}
