<div *ngIf="actualite">
  <div class="background" [ngStyle]="{
    'background-image': 'url(' + actualite.urlImage + ')',
    'background-size': 'cover',
    'background-repeat': 'no-repeat',
    'background-position': 'center',
    'height' : '200px'
  }"></div>
  <div class="actu-card">
    <div class="actu-body px-3 py-5" >
      <h4>{{actualite.titre}}</h4>
      <p class="my-3" [innerHTML]="actualite.texte_vignette"></p>
      <a class="text-yellow MB-5" href="actualites/{{actualite?.slug}}">LIRE LA SUITE</a>
    </div>
    <div class="border-top" >
      <p class="px-3 py-3"> {{actualite.dateDebut.date | date: 'd-MM-Y'}}</p>
    </div>
  </div>
</div>


