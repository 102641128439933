import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-shortcut',
  templateUrl: './contact-shortcut.component.html',
  styleUrls: ['./contact-shortcut.component.scss']
})
export class ContactShortcutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
