<div class="dpe-ges-container">
  <div *ngIf="dpeValue !== null; else dpeNotProvided">
    <table class="dpe-ges">
      <tr class="dpe-ges-line">
        <th>{{ options.label_mini }}</th>
      </tr>
      <ng-container *ngFor="let lettre of letter_list">
        <tr class="dpe-ges-line">
          <td>
            <div class="img">
              <img [src]="getDpeImagePath(lettre)" alt="" />
              <div *ngIf="lettre === getDpeLetter()" class="value dpe">{{ dpeValue }}</div>
            </div>
          </td>
        </tr>
      </ng-container>
      <tr class="dpe-ges-line">
        <th>{{ options.label_maxi }}</th>
      </tr>
      <tr class="dpe-ges-line">
        <td>{{ options.label_legend }}</td>
      </tr>
    </table>
  </div>
  <ng-template #dpeNotProvided>
    <table class="dpe-ges">
      <tr class="dpe-ges-line">
        <th>{{ options.label_none }}</th>
      </tr>
    </table>
  </ng-template>

  <!-- GES -->
  <div *ngIf="gesValue !== null; else gesNotProvided">
    <table class="dpe-ges">
      <tr class="dpe-ges-line">
        <th>{{ options.label_ges_min }}</th>
      </tr>
      <ng-container *ngFor="let lettre of letter_list">
        <tr class="dpe-ges-line">
          <td>
            <div class="img">
              <img [src]="getGesImagePath(lettre)" alt="" />
              <div *ngIf="lettre === getGesLetter() && gesValue > 99" class="value ges">{{ gesValue }}</div>
              <div *ngIf="lettre === getGesLetter() && gesValue < 100" class="value gesLower">{{ gesValue }}</div>
            </div>
          </td>
        </tr>
      </ng-container>
      <tr class="dpe-ges-line">
        <th>{{ options.label_ges_max }}</th>
      </tr>
      <tr class="dpe-ges-line">
        <td>{{ options.label_legend_ges }}</td>
      </tr>
    </table>
  </div>
  <ng-template #gesNotProvided>
    <table class="dpe-ges">
      <tr class="dpe-ges-line">
        <th>{{ options.label_none }}</th>
      </tr>
    </table>
  </ng-template>
</div>

