import { Component, OnInit } from '@angular/core';
import {TitleStrategy} from '@angular/router';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  constructor(
    private  title: Title
  ) {
    this.title.setTitle('Services - Office notarial Pacé');
  }

  ngOnInit(): void {
  }

}
