<app-intro title="Mentions Légales"></app-intro>
<div class="container">
  <div class="row text-center text-lg-start mt-2">
    <h2 id="mentions">Mentions légales</h2>
    <p>Le site office-notarial-pace.com est exploité par :</p><br/><br/>

    <p>L'Office notarial du pace ponant </p><br/><br/>

    <p>
      SCP GUICHARD-NAUT-MESSAGER<br/>
      14 boulevard Dumaine de la Josserie<br/>
      BP 74136<br/>
      35740 PACE<br/>
      Tél. : 02 99 60 61 08
    </p><br/><br/>

    <p>Responsable d'édition : Maître Catherine GUICHARD, Maître Josselin NAUT et Maître Guy MESSAGER</p><br/><br/>

    <p>
        L'Office notarial du pace ponant se réserve le droit de ne pas publier les annonces soumises par des particuliers si celles-ci n'entrent pas dans le champ des biens immobiliers proposés à la vente ou à la location ou si les conditions de l'offre apparaissent inadaptées aux conditions actuelles du marché immobilier.
    </p><br/>
    <p>
        Toutes les images, photos, illustrations et mises en page figurant sur le site office-notarial-pace.com sont la propriété exclusive de l'Office notarial du pace ponant et ayants-droits. Toute exploitation de celles-ci à des fins commerciales est interdite, et fera systématiquement l’objet de poursuites. l'Office notarial du pace ponant ne pourrait être en aucun cas tenue pour responsable du contenu des sites de ses partenaires.
    </p><br/>

    <h3>Information hébergeur</h3>
    <p>La conception graphique, le développement informatique et l’hébergement ont été réalisés / sont assurés
        par la société</p>
    <p>Agence 11h20, PA Beaujardin, 35410 Châteaugiron, au capital de 3 000 € RCS</p>
    <p>RENNES SIRET N°84204545200021</p>
    <p><a href="tel:0299833766">Tél. : 02 99 83 37 66</a></p>

    <br/><br/>
    <h2 id="recommandationsCNIL">Recommandations CNIL</h2>

    <h3>I - Qu'est ce qu'un cookie</h3>
    <p>Un cookies est un fichier texte sauvegardé sur votre ordinateur ou votre appareil mobile puis récupéré lors de vos visites ultérieures.
    </p>
    <h3>II - Les obligations légales</h3>
    <p>L'internaute qui se rend sur le site éditeur se doit d' être informé de la finalités des cookies, l' éditeur doit obtenir son consentement et doit fournir aux internautes un moyen de les refuser. La durée de validité de ce consentement est de 13 mois. L' obligation du recueil du consentement s'impose aux responsables des sites internet, aux éditeurs d'applications, aux régies publicitaires, aux éditeurs de solutions de mesures d'audience ainsi qu'aux réseaux sociaux. Certains cookies nécessitent une information préalable et une demande de consentement au prêt des internautes, tels que les cookies liés aux opérations relatives à la publicité et les cookies de mesures d'audience.
    </p>
    <h3>III - L'affichage d'un bandeau d'information</h3>
    <p>Ce bandeau informe les internautes sur les finalités précises des cookies utilisés, la possibilité de s'opposer à ces cookies et de changer les paramètres en mettant un lien présent sur le bandeau. Le lien «en savoir plus» permet à l'utilisateur de paramétrer les cookies, les accepter ou les refuser. Ce lien doit informer de manière claire et concise l'usager ce qu'est un cookie.
    </p>
    <h3>IV - Les solutions Webanalytics</h3>
    <p>Piwik et Google Analytics sont des outils de mesures d'audience qui permettent d'obtenir des informations sur la navigation des visiteurs. Ce sont des outils dispensés de consentement mais devant respecter certaines normes. Le mécanisme d'opposition aux cookies doit être accessible simplement, les cookies déposés servent uniquement a la production de statistiques anonymes, les cookies ne doivent pas permettre de suivre la navigation de l'internaute sur d'autres sites, les cookies permettant la traçabilité des internautes et les adresse IP ne doivent pas être conservés au-delà de 13 mois à compter de la dernier visite.
    </p>
    <h3>V - Les boutons sociaux</h3>
    <p> Share Privacy est un outil qui permet d'intégrer les boutons des principales plates-formes sociales sans envoyer de cookies avant d'obtenir un consentement préalable de l'utilisateur. Les boutons sociaux sont des plug-ins sociaux qui permettent aux concepteurs de site d'ajouter facilement à leurs pages web des fonctionnalités permettant de faciliter le partage du contenu de leurs sites sur les différentes plates-formes sociales. Ce sont des outils qui nécessitent un consentement préalable de l'internaute.
    </p>
    <br/>
  </div>

  <div class="container">
    <app-contact-shortcut class="margin-negative"></app-contact-shortcut>
  </div>
  <div class="text-center my-3">
    <a href="#" class="btn btn-yellow text-white">VOIR PLUS <i class="ms-3 fa-solid fa-arrow-right text-white"></i></a>
  </div>
</div>
