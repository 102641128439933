<app-intro title="Contactez-nous"></app-intro>
<div class="container text-center text-lg-start">
  <h2 class="text-cormorant my-5">Demande de renseignement</h2>
  <p>Si vous souhaitez avoir plus d'informations concernant un bien ou si vous avez des questions, n'hésitez pas à remplir le formulaire, nous vous répondrons dans les plus brefs délais</p>
  <form
    class="my-4"
    (ngSubmit)="onFormSubmit(contactForm.value)"
    #contactForm="ngForm"
  >
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Nom" [(ngModel)]="name" name="name">
        </div>
      </div>
      <div class="col-lg-6 mt-lg-0 mt-3">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Prénom" [(ngModel)]="firstName" name="firstName">
        </div>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-lg-6">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Adresse" [(ngModel)]="address" name="address">
        </div>
      </div>
      <div class="col-lg-3 my-lg-0 my-3">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Code postal" [(ngModel)]="postalCode" name="postalCode">
        </div>
      </div>
      <div class="col-lg-3">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="VIlle" [(ngModel)]="city" name="city">
        </div>
      </div>
    </div>
    <div class="form-group my-3">
      <input type="email" class="form-control" placeholder="Email" [(ngModel)]="email" [email]="true" name="email" required>
    </div>
    <div class="form-group my-3">
      <textarea class="form-control" placeholder="Comment pouvons-nous vous aider" [ngStyle]="{height: '200px'}" [(ngModel)]="message" name="message"></textarea>
    </div>

    <div class="form-group d-flex">
      <input type="checkbox" name="consentement" [(ngModel)]="consentement">
      <p class="text-consentement">En soumettant ce formulaire, j’accepte que les informations saisies dans ce formulaire soient utilisées, exploitées, traitées pour permettre de me recontacter, pour m’envoyer des informations, dans le cadre de la relation commerciale qui découle de cette demande. <a class="checkbox-consentement" target="_blank" href="/mentions-legales">* En savoir plus.</a></p>
    </div>

    <div class="form-group my-3">
      <!-- <button
        class="btn btn-yellow g-recaptcha"
        data-sitekey="6LfqbJMlAAAAAOYPGv9PqdGQLW8qUEbpBDDyigdT"
        data-callback='onFormSubmit'
        type="submit"
        data-action='submit'
      > ENVOYER</button> -->

      <button type="button" class="btn btn-yellow" (click)="send(contactForm)">ENVOYER</button>
    </div>

    <div *ngIf="formSuccess" class="form-group my-3 text-success text-center"><b><u>Le formulaire a été envoyé avec succès.</u></b></div>
    <div *ngIf="formError" class="form-group my-3 text-danger text-center"><b><u>Une erreur est survenue.</u></b></div>
  </form>
</div>
