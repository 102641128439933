<app-intro title="Nos tarifs"></app-intro>
<div class="container-fluid text-lg-start text-center">
  <div class="row">
    <div class="col-lg-6 about-left  p-5">
      <h2 class="mb-4">TARIF DES EMOLUMENTS DE NEGOCIATION</h2>

      <table class="my-5 table table-striped table-bordered table-responsive w-auto">
        <thead>
          <tr>
            <th>EUROS (Prix net vendeur)</th>
            <th>H.T*</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>De 0 à 15.000€</td>
            <td>1.300€</td>
          </tr>
          <tr>
            <td>De 15.000€ à 50.000€</td>
            <td>2.000€</td>
          </tr>
          <tr>
            <td>Au-delà de 50.000€</td>
            <td>3,5%</td>
          </tr>
        </tbody>
      </table>
      <p><i class="fa-solid fa-check text-black"></i> Droit de la famille</p>
      <p><i class="fa-solid fa-check  text-black"></i> Affaire et des sociétés</p>
      <p class="mb-4"><i class="fa-solid fa-check  text-black"></i> Droit immobilier et de la fiscalité</p>
    </div>
    <div class="col-lg-6 about-right">
    </div>
  </div>
</div>
<div class="container my-3">
  <app-contact-shortcut></app-contact-shortcut>
</div>
