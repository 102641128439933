<app-intro title="" subtitle=""  image="../../../assets/images/vente.png"></app-intro>
<div class="container my-5">
  <div class="actu-container">
    <div class="img-container">
      <img src="{{actualite.urlImage}}" alt="">
    </div>
    <div>
      <div class="titre">
        <h4>{{actualite.titre}}</h4>
      </div>
      <div class="body" [innerHTML]="actualite.texte_vignette">

      </div>
    </div>
  </div>

  <div class="text-end">
    <a *ngIf="!actualite.id" href="{{actualite?.link}}" target="_blank" class="btn btn-yellow mx-2"> Découvrez l'article complet sur notaires.fr</a>
    <a href="#" class="btn btn-yellow"> RETOUR AUX ACTUALITÉS</a>
  </div>
</div>
