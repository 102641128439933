<app-intro title="Acheter ou louer"></app-intro>
<div class="bg-grey py-5">
  <div class="container ">
    <div class="d-flex justify-content-between  align-items-center-bottom">
      <div>
        <h2 class="text-purple">{{ titre}}</h2>
        <p class="text-yellow">{{(bien.prix + bien.fraisNego + bien.fraisActe)}} € <span class="hni">HNI</span></p>
        <div class="my-3 text-center text-lg-start">
          <ul>
            <li>
              Réf: {{bien.reference}}
            </li>
            <li>
              {{bien.nbPieces}} pièces
            </li>
            <li>
              {{bien.surfaceHabitable}}m2
            </li>
          </ul>
        </div>
      </div>
      <div>
        <a href="offres" class="btn btn-yellow">
          <i class="fa-solid fa-arrow-left text-white"></i>
          RETOUR
        </a>
      </div>
    </div>

    <div class="row text-center text-lg-start">
      <div class="col-lg-6">
        <div class="carousel-container">
          <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-indicators" *ngIf="images?.length > 1">
              <button *ngFor="let img of images let i = index; let first = first" type="button"
                      data-bs-target="#carouselExampleControls" [attr.aria-label]="'Slide ' + (i+1)"
                      [attr.data-bs-slide-to]="i"
                      [ngClass]="{ active: first }"></button>
            </div>
            <div class="carousel-inner" *ngIf="arrayLength !== 0">
              <div class="carousel-item"
                   *ngFor="let img of images let i = index; let first = first"
                   [ngClass]="{ active: first }">
                <img src="{{ img.file }}" class="d-block w-100">
              </div>
            </div>
            <div *ngIf="arrayLength == 0">
              <div style="width: 500px !important; margin: auto">
                <img src="{{ bien.images[0]?.file }}" class="d-block w-100">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
                    data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
                    data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <!-- <img class="img-fluid" src="{{bien.images[0]?.file}}"> -->
        <div class="row d-flex px-3 mb-3 my-4">
          <div class="col-lg-4 ps-0">
            <div class="d-flex align-items-center border-end">
              <i class="fa-regular fa-circle-user fa-2x text-purple"></i>
              <div class="ms-3 ">
                <p class="m-0 text-purple">{{bien.negociateur}}</p>
                <!-- <p class="m-0">Negociateur</p> -->
              </div>
            </div>
          </div>
          <div class="col-lg-8 mt-3 mt-lg-0">
            <p class="m-0 ">{{bien.phone}}</p>
            <p class="m-0 ">{{bien.mail}}</p>
          </div>
        </div>
        <div>
          <p class="mb-3">Descriptif du bien immobilier</p>
          <p>{{bien.annonce}}</p>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="bg-white p-4">
          <p *ngIf="bien.transaction === 'vente'">Dont prix de vente: {{bien.prix}} € HNI</p>
          <p *ngIf="bien.transaction === 'location'">Loyer mensuel: {{bien.prix}} €</p>
          <p *ngIf="bien.transaction === 'vente'">Dont EN* TTC à la charge de l'acquéreur: {{(bien.fraisNego + bien.fraisActe)}} €</p>
          <p *ngIf="bien.transaction === 'location'">Frais de bail: {{(bien.fraisNego + bien.fraisActe)}} €</p>
          <div class="mt-3" *ngIf="bien.surfaceHabitable">
            <p><i class="fa-solid fa-square-dashed"></i> Surface habitable en  m² <br><strong>{{bien.surfaceHabitable}}m²</strong></p>
          </div>
          <div class="my-3" *ngIf="bien.surfaceSejour">
            <p><i class="fa-solid fa-square-dashed"></i> Surface séjour en  m² <br><strong>{{bien.surfaceSejour}}m²</strong></p>
          </div>
          <div class="my-3" *ngIf="bien.surfaceTerrain">
            <p><i class="fa-solid fa-tree"></i> Surface terrain <br><strong>{{bien.surfaceTerrain}}</strong></p>
          </div>
          <div class="my-3" *ngIf="bien.nbPieces">
            <p><i class="fa-sharp fa-solid fa-table-cells-large"></i> Nombre de pièces <br><strong>{{bien.nbPieces}}</strong></p>
          </div>
          <div class="my-3" *ngIf="bien.nbChambres">
            <p><i class="fa-solid fa-house"></i> Nombre de chambres <br><strong>{{bien.nbChambres}}</strong></p>
          </div>
          <div>
            <!-- <img class="img-fluid" src="assets/toRemove/dpe.jpg"> -->
            <app-dpe-ges [dpeValue]="bien.dpenrj" [gesValue]="bien.dpeges"></app-dpe-ges>
          </div>
        </div>
      </div>
    </div>

    <app-contact-shortcut></app-contact-shortcut>
  </div>
</div>
