import { Component, Input, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';
import { ActualiteService } from '../../_services/actualite.service';

@Component({
  selector: 'app-news-page',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  actualites: any;
  p: number = 1;

  constructor(private title: Title, private actualiteService: ActualiteService) {
    this.title.setTitle('Actualités - Office notarial Pacé');
  }

  ngOnInit(): void {
    this.getActualites();
  }

  getActualites() {
    this.actualiteService.getActualites().subscribe(data => {
      this.actualites = data;
    })
  }

}
