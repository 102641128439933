import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {
  @Input() title: string = "";
  @Input() image = '../../../assets/images/intro.jpg';
  @Input() subtitle = '';
  constructor() { }

  ngOnInit(): void {
  }

}
