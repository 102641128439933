<app-intro title="Nos services"></app-intro>
<div class="bg-grey py-5">
  <div class="container text-lg-start text-center">
    <div class="row d-flex">
      <div class="col-lg-3 service">
        <div class="background" [ngStyle]="{'background-image':'url(../../../assets/images/services/Background.png)'}"></div>
        <div>
          <div class=" px-3 py-5 bg-white" >
            <h4>LOCATION</h4>
            <p class="my-3">L'étude de PACE vous accompagne pour la recherche de locataires, ainsi que la rédaction des baux, la gestion des biens (habitations, rurales ou commerciales). Un collaborateur de l'étude est responsable de ce service ...</p>
            <!-- <a href="#" class="text-decoration-none text-black">EN SAVOIR PLUS </a> -->
          </div>
        </div>
      </div>
      <div class="col-lg-3 service  my-3 my-lg-0">
        <div class="background" [ngStyle]="{'background-image':'url(../../../assets/images/services/Background-1.png)'}"></div>
        <div>
          <div class=" px-3 py-5 bg-white" >
            <h4>DROIT</h4>
            <p class="my-3">L'étude vous accompagne pour votre protection en cas de succession ou séparation. Une donation entre époux, une adoption, un PACS, une donation-partage, un testament permettent d'organiser votre succession... <br></p>
            <!-- <a href="#" class="text-decoration-none text-black">EN SAVOIR PLUS </a> -->
          </div>
        </div>
      </div>
      <div class="col-lg-3 service">
        <div class="background" [ngStyle]="{'background-image':'url(../../../assets/images/services/Background-2.jpg)'}"></div>
        <div>
          <div class=" px-3 py-5 bg-white" >
            <h4>IMMOBILIER</h4>
            <p class="my-3">L'étude de PACE vous aide pour la recherche ou la vente de biens immobiliers, l'évaluation de vos biens, avec une personne affectée à la négociation immobilière...<br><br><br></p>
            <!-- <a href="#" class="text-decoration-none text-black">EN SAVOIR PLUS </a> -->
          </div>
        </div>
      </div>
      <div class="col-lg-3 service">
        <div class="background" [ngStyle]="{'background-image':'url(../../../assets/images/services/Background-3.jpg)'}"></div>
        <div>
          <div class=" px-3 py-5 bg-white" >
            <h4>ENTREPRISE</h4>
            <p class="my-3">L'office Notarial du Ponant vous accompagne dans la vie de l'entreprise, anticipation de l'incapacité du chef d'entreprise, la rédaction des mandats à effet posthume et de protection future...<br><br></p>
            <!-- <a href="#" class="text-decoration-none text-black">EN SAVOIR PLUS </a> -->
          </div>
        </div>
      </div>
    </div>
    <app-contact-shortcut></app-contact-shortcut>
  </div>
</div>
