import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BiensService } from 'src/app/_services/biens.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-offer-detail',
  templateUrl: './offer-detail.component.html',
  styleUrls: ['./offer-detail.component.scss']
})
export class OfferDetailComponent implements OnInit {
  slug: any;
  bien: any;
  arrayLength: any;
  images: any;
  titre: string = '';
  constructor(
    private title: Title,
    private activated: ActivatedRoute,
    private bienService: BiensService,
    private router: Router
  ) {
    this.title.setTitle('Actualités - Office notarial Pacé');
    this.slug = this.activated.snapshot.paramMap.get('slug');
  }

  ngOnInit(): void {
    this.getBien();
  }

  getBien() {
    this.bienService.getBien(this.slug).subscribe(data => {
      if (data && Object.keys(data).length > 0) {
        this.bien = data;
        this.images = data.images;
        this.arrayLength = this.images.length;

        switch (this.bien.type) {
          case 'APPT':
            this.titre = this.bien.transaction === 'location' ? 'Location - Appartement - ' + this.bien.ville + '(' + this.bien.departement +') - ' + this.bien.surfaceHabitable + 'm² - ' + this.bien.nbPieces + ' pièces' : 'Vente' + ' - Appartement - ' + this.bien.ville + '(' + this.bien.departement +') - ' + this.bien.surfaceHabitable + 'm² - ' + this.bien.nbPieces + ' pièces';
            break;
          case 'FMCO':
            this.titre = this.bien.transaction === 'location' ? 'Location - Local commercial - '+ this.bien.ville + '(' + this.bien.departement +') - ' + this.bien.surfaceHabitable + 'm² - ' + this.bien.nbPieces + ' pièces' : 'Vente' + ' - Local commercial - ' + this.bien.ville + '(' + this.bien.departement +') - ' + this.bien.surfaceHabitable + 'm² - ' + this.bien.nbPieces + ' pièces';
            break;
          case 'MAIS':
            this.titre = this.bien.transaction === 'location' ? 'Location - Maison - '+ this.bien.ville + '(' + this.bien.departement +') - ' + this.bien.surfaceHabitable + 'm² - ' + this.bien.nbPieces + ' pièces' : 'Vente' + ' - Maison - ' + this.bien.ville + '(' + this.bien.departement +') - ' + this.bien.surfaceHabitable + 'm² - ' + this.bien.nbPieces + ' pièces';
            break;
          case 'IMMR':
            this.titre = this.bien.transaction === 'location' ? 'Location - Immeuble - '+ this.bien.ville + '(' + this.bien.departement +') - ' + this.bien.surfaceHabitable + 'm² - ' + this.bien.nbPieces + ' pièces' : 'Vente' + ' - Immeuble - ' + this.bien.ville + '(' + this.bien.departement +') - ' + this.bien.surfaceHabitable + 'm² - ' + this.bien.nbPieces + ' pièces';
            break;
          case 'PROP':
            this.titre = this.bien.transaction === 'location' ? 'Location - Propriété - '+ this.bien.ville + '(' + this.bien.departement +') - ' + this.bien.surfaceHabitable + 'm² - ' + this.bien.nbPieces + ' pièces' : 'Vente' + ' - Propriété - ' + this.bien.ville + '(' + this.bien.departement +') - ' + this.bien.surfaceHabitable + 'm² - ' + this.bien.nbPieces + ' pièces';
            break;
          case 'TEBA':
            this.titre = this.bien.transaction === 'location' ? 'Location - Terrain à batir - '+ this.bien.ville + '(' + this.bien.departement +') - ' + this.bien.surfaceHabitable + 'm² - ' + this.bien.nbPieces + ' pièces' : 'Vente' + ' - Terrain à batir - ' + this.bien.ville + '(' + this.bien.departement +') - ' + this.bien.surfaceHabitable + 'm² - ' + this.bien.nbPieces + ' pièces';
            break;
        }
      } else {
        // redirect to 404
        this.router.navigate(['/404']);
      }
    })
  }

}
